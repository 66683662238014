import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CommonModal } from '../CommonModal';

import { DeleteIcon, EmailIcon } from 'assets';
import { CommonModalType } from 'common';
import { ControlledTextField } from 'components';
import { emailSchema } from 'config';
import { useActions, useAppSelector } from 'hooks';
import { invitingManagersSelect, slotsThunk } from 'state';
import { Button, Typography } from 'ui-kit';
import './AddUserModal.scss';

type FormType = z.infer<typeof emailSchema>;

export const AddUserModal = ({ showModal, setShowModal }: CommonModalType) => {
  const { deleteManager, addManager, getManagers } = useActions(slotsThunk);
  const invitingManagers = useAppSelector(invitingManagersSelect);

  const onCloseClick = () => {
    setShowModal(false);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>({
    resolver: zodResolver(emailSchema),
    defaultValues: { email: '' },
  });
  const onSubmit: SubmitHandler<FormType> = ({ email }) => {
    addManager({ email: email.trim(), onCloseClick: reset });
  };

  const onDeleteManagerClick = (email: string) => {
    deleteManager({ email });
  };

  useEffect(() => {
    if (showModal) {
      getManagers();
    }
  }, [showModal]);

  return (
    <CommonModal showModal={showModal} closeModal={onCloseClick}>
      <section className="addUserModal">
        <Typography variant="h3" className="addUserModal_h3">
          Add Manager
        </Typography>
        {invitingManagers?.length > 0 && (
          <section className="usersTable" style={{ height: 'auto' }}>
            <section className="usersTableHeader">
              <Typography variant="p2" style={{ width: '100%' }}>
                Email
              </Typography>
            </section>

            <div className="usersTable_box">
              {invitingManagers?.map(user => {
                return (
                  <div
                    className="usersTableRow"
                    style={{ cursor: 'default' }}
                    key={user.id}
                  >
                    <Typography
                      variant="p2"
                      className="addUserModal_tableRow_p2"
                      style={{ cursor: 'default', width: '100%' }}
                    >
                      {user.email}
                    </Typography>
                    <div
                      className="addUserModal_delete"
                      onClick={() => onDeleteManagerClick(user.email)}
                    >
                      <DeleteIcon width="20px" />
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            label="Email"
            type="text"
            name="email"
            control={control}
            autoComplete="off"
            icon={<EmailIcon className="addUserModal_icon" />}
            className="addUserModal_textField"
            placeholder="Enter manager email"
          />

          <Button type="submit" className="addUserModal_button" disabled={isSubmitting}>
            Invite
          </Button>
        </form>
      </section>
    </CommonModal>
  );
};
