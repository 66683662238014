import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InvitingManagerType, SlotType, UsersInSlotType } from 'api';

const initialState = {
  slots: {} as SlotType,
  certainSlot: {} as UsersInSlotType | undefined,
  invitingManagers: [] as InvitingManagerType[],
};

const slice = createSlice({
  name: 'slots',
  initialState,
  reducers: {
    setSlots: (state, action: PayloadAction<SlotType>) => {
      state.slots = action.payload;
    },
    setCertainSlot: (state, action: PayloadAction<UsersInSlotType | undefined>) => {
      state.certainSlot = action.payload;
    },
    setInvitingManagers: (state, action: PayloadAction<InvitingManagerType[]>) => {
      state.invitingManagers = action.payload;
    },
  },
});

export const slotsActions = slice.actions;

export const slotsReducer = slice.reducer;
